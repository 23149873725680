import React, { useReducer, useContext, createContext } from "react";
import { reducer, cartItemsTotalPrice, cartItemsTotalQuantity } from "./cart.reducer";
import { useStorage } from "../../utils/use-storage";
const CartContext = createContext({});
const INITIAL_STATE = {
  isOpen: false,
  items: [],
  isRestaurant: false,
  coupon: null,
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);
  const addItemHandler = (item, quantity = 1) => {
    dispatch({ type: "ADD_ITEM", payload: { ...item, quantity } });
  };

  const removeItemHandler = (item, quantity = 1) => {
    dispatch({ type: "REMOVE_ITEM", payload: { ...item, quantity } });
  };

  const clearItemFromCartHandler = (item) => {
    dispatch({ type: "CLEAR_ITEM_FROM_CART", payload: item });
  };

  const clearCartHandler = () => {
    dispatch({ type: "CLEAR_CART" });
  };
  const toggleCartHandler = () => {
    dispatch({ type: "TOGGLE_CART" });
  };
  const couponHandler = (coupon) => {
    dispatch({ type: "APPLY_COUPON", payload: coupon });
  };
  const removeCouponHandler = () => {
    dispatch({ type: "REMOVE_COUPON" });
  };
  const rehydrateLocalState = (payload) => {
    dispatch({ type: "REHYDRATE", payload });
  };
  const toggleRestaurant = () => {
    dispatch({ type: "TOGGLE_RESTAURANT" });
  };
  const isInCartHandler = (id) => {
    return state.items?.some((item) => item._id === id);
  };
  const getItemHandler = (id) => {
    return state.items?.find((item) => item._id === id);
  };
  const getItemQuantityHandler = (id) => {
    // return state.items?.find((item) => item._id === id);
    let itemCount = 0
    state.items?.map((item) => {
      if (item._id === id) {
        itemCount = itemCount + item.quantity
      }
    })
    return itemCount
  };
  const getCartItemsPrice = () => cartItemsTotalPrice(state.items).toFixed(2);
  const getCartItemsTotalPrice = () =>
    cartItemsTotalPrice(state.items, state.coupon).toFixed(2);

  const getDiscount = () => {
    const total = cartItemsTotalPrice(state.items);
    const discount = state.coupon
      ? (total * Number(state.coupon?.discountInPercent)) / 100
      : 0;
    return discount.toFixed(2);
  };
  // const getItemsCount = state.items?.reduce(
  //   (acc, item) => acc + item.quantity,
  //   0
  // );


  const getCartItemsTotalQuantity = () => cartItemsTotalQuantity(state.items)

  let totalItems = 0;
  const getItemsCount = state.items?.map((item => {
    return totalItems += parseInt(item.quantity)
  }))

  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getItemQuantityHandler,
    toggleCartHandler,
    getCartItemsTotalPrice,
    getCartItemsTotalQuantity,
    getCartItemsPrice,
    couponHandler,
    removeCouponHandler,
    getDiscount,
    toggleRestaurant,
  };
};

export const CartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getItemQuantityHandler,
    toggleCartHandler,
    getCartItemsTotalPrice,
    getCartItemsTotalQuantity,
    couponHandler,
    removeCouponHandler,
    getCartItemsPrice,
    getDiscount,
    toggleRestaurant,
  } = useCartActions();
  const { rehydrated, error } = useStorage(state, rehydrateLocalState);

  return (
    <CartContext.Provider
      value={{
        isOpen: state.isOpen,
        items: state.items,
        coupon: state.coupon,
        isRestaurant: state.isRestaurant,
        cartItemsCount: getCartItemsTotalQuantity,
        itemsCount: getItemsCount,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: clearItemFromCartHandler,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        getItemQuantity: getItemQuantityHandler,
        toggleCart: toggleCartHandler,
        calculatePrice: getCartItemsTotalPrice,
        calculateSubTotalPrice: getCartItemsPrice,
        applyCoupon: couponHandler,
        removeCoupon: removeCouponHandler,
        calculateDiscount: getDiscount,
        toggleRestaurant,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
